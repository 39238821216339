// 'use strict';
$(document).ready(function () {
    var $carouselsWrappers = $('.carousel');

    $carouselsWrappers.each(function () {
        var $this = $(this);
        var carousel = $($this[0]);
        var $sliderCount = carousel.find('.slider-count');
        var $slickElement = carousel.find('.carousel-inner');
        var autoPlayEnabled = $this.data('autoplay-enabled');
        var slidesInterval = $this.data('slides-interval');

        $slickElement.on('init reInit afterChange', function (event, slick, currentSlide) {
            var i = (currentSlide || 0) + 1;
            $sliderCount.text(i + '/' + slick.slideCount);
        });
    
        $slickElement.slick({
            autoplay: autoPlayEnabled,
            infinite: true,
            speed: 200,
            autoplaySpeed: slidesInterval,
            fade: true,
            cssEase: 'ease-in-out',
            arrows: true,
            prevArrow: carousel.find('.text-overlay-arrows .prev-arrow'),
            nextArrow: carousel.find('.text-overlay-arrows .next-arrow'),
            pauseOnHover: true
        });
    });

    $('.fundraising-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
        dots: true
    });
});
